.Container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  width: 100%;
  fill: currentcolor;
}

/* need to have a higher order of specificity since HeroBanner fights it */
.Container.fullViewportHeight {
  min-height: 100vh !important;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3.5rem auto;
  width: 100%;
  max-width: 1400px;
}

@media screen and (--small-viewport) {
  .content {
    width: 85%;
  }
}

/* Dark Themes */
.secondary {
  background-color: var(--secondary);
  color: var(--white);
}

.secondary svg {
  fill: var(--white);
}

/* Light Themes */
.gray {
  background-color: var(--gray);
  color: var(--secondary);
}

.white {
  background-color: var(--white);
  color: var(--secondary);
}

.white svg,
.gray svg {
  fill: var(--secondary);
}
