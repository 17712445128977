.Button {
  display: inline-block;
  border: 3px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  line-height: 1.25;
  min-width: 175px;
  padding: 1rem 0.75rem 0.75rem;
  white-space: nowrap;
  transition: 0.2s linear background-color, 0.2s linear color;
  text-shadow: none;
}

.primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--secondary);
  outline-color: var(--primary);
}

.primary:hover:not(:disabled),
.primary:focus-visible:not(:disabled) {
  background-color: rgba(0, 0, 0, 0);
  color: var(--primary);
}

.secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: var(--primary);
  outline-color: var(--secondary);
}

.secondary:hover:not(:disabled),
.secondary:focus-visible:not(:disabled) {
  background-color: rgba(0, 0, 0, 0);
  color: var(--secondary);
}

.fullWidth {
  width: 100%;
}

.disabled {
  border: 3px solid transparent;
  opacity: 0.6;
}

.disabled:hover {
  border: 3px solid transparent;
  cursor: not-allowed;
}

a.Button {
  font-family: var(--primaryFontFamily), sans-serif;
}
